.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }  
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.detect-text span h3{
  font-size: 50px;
  font-weight: 600;
  color: #E9510E;
  margin-bottom: 3px;
  position: absolute;
  left: 49%;
  top: 260px;
  margin-bottom: 10px;
}

.detect-text span h4{
  font-size: 20px;
  font-weight: 600;
  color: #E9510E;
  margin-bottom: 3px;
  position: absolute;
  left: 44%;
  top: 320px;
  width: 180px;
}

/*media query should come below of actuals*/
@media screen and (max-width: 350px) {

  .detect-text span h3{
    font-size: 50px;
    font-weight: 600;
    color: #E9510E;
    margin-bottom: 3px;
    position: absolute;
    left: 40%;
    top: 600px;
    margin-bottom: 10px;
  }
  
  .detect-text span h4{
    font-size: 20px;
    font-weight: 600;
    color: #E9510E;
    margin-bottom: 3px;
    position: absolute;
    left: 12%;
    top: 670px;
    width: 240px;
  }
}

.photbtn{
	background-color: #F28B2D !important;
  color: #fff !important;
  font-size: 14px !important;
  padding: 8px 32px !important;
  border: none !important;
  cursor: pointer !important;
  border-radius: 5px !important;
  text-align: center !important;
  margin: 0 10px 0 0px !important;
}

.photbtn:hover {
  background-color: #E9510E !important;
  color: #fff !important;
}

.errorMsg{
  color: red;
}
.width100 {
  width: 100%;
}
.cr-pointer{
  cursor: pointer;
}
.info-icon {
  color:#3ec8ee;
}
.info-icon:hover {
  color: #E9510E;
}


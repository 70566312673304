
.white-box-curved-popup-reg {
    background-color: #fff;
    box-shadow: 0 10px 10px -14px black;
    padding: 60px 20px;
    margin-bottom: 0px;
    border-radius: 7px;
    margin-top: 0px;
    text-align: center;
    height: 300px;
}


.white-box-curved-popup-enq {
    background-color: #fff;
    box-shadow: 0 10px 10px -14px black;
    padding: 60px 20px;
    margin-bottom: 0px;
    border-radius: 7px;
    margin-top: 0px;
    text-align: center;
}

.modal-dialog-reg {
    max-width: 600px !important;
    margin: 1.75rem auto;
}

.modal-dialog-enq {
    max-width: 300px !important;
    margin: 1.75rem auto;
}

.modal.show .modal-dialog {
    transform: none;
    padding-top: 250px;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
}

.body-content{
    padding-bottom: 15px;
}
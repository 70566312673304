#sp-qr-container{
    border: 2px solid rgb(233, 81, 14);
    padding: 10px;   
}

.flex-container{
    display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
    
    justify-content: center;
    align-items: center; 
  }
  
  
